import React from 'react'
import { Container } from 'reactstrap'

import SEO from '../components/SEO'

import './karriere.scss'

const title = 'Karriere | Kipa Nachrichtentechnik'
const description =
  'Offene Stellenangebote & Arbeitsplätze beim Innsbrucker Unternehmen Kipa Nachrichtentechnik. Machen Sie Karriere mit uns!'

const CareerPage = () => {
  return (
    <div className="Career">
      <SEO title={title} description={description} />
      <Container>
        <h1>Karriere</h1>
        <p>
          Die Kipa Nachrichtentechnik ist ein Unternehmen, das seit über 20
          Jahren in der Kommunkationstechnik tätig ist.
        </p>
        <h2>Offene Stellen</h2>
        <div>
          <h3>Informations- und Kommunikationstechniker</h3>
          <p>
            Die ausgeschriebene <strong>Vollzeitstelle</strong> bietet
            anspruchsvolle und zukunftsorientierte Perspektiven in einem
            wachsenden Geschäftsfeld, das durch Engagement aktiv mitgestaltet
            werden kann.
            <br />
            <br />
            Gemäß §9 Absatz 2 Gleichbehandlungsgesetz sind wir verpflichtet, das
            Mindestgehalt lt. Kollektivvertrag zu nennen, welches sich für diese
            Position auf €35.569,80 Brutto pro Jahr beläuft. Verhandlungsbasis
            je nach Qualifikation.
          </p>
          <h4>Voraussetzungen</h4>
          <ul>
            <li>Interesse an der LWL Technik und Internettechnologie</li>
            <li>Analytisches und konzeptionelles Denken</li>
            <li>Führerschein der Klasse B</li>
            <li>
              Selbstständiges und zielorientiertes Arbeiten sowie Flexibilität
            </li>
            <li>Handwerkliches Geschick</li>
            <li>Teamfähigkeit</li>
            <li>Kundenorientiert</li>
            <li>Engagiert und zuverlässig</li>
            <li>
              Idealerweise Erfahrungen als Elektroniker und in der HF-Technik
            </li>
          </ul>
        </div>
        <div>
          <p>
            Haben wir Ihr Interesse geweckt? Dann richten Sie Ihre
            aussagekräftige Bewerbung inklusive aktuellem Lebenslauf und Foto,
            sowie Arbeitszeugnisse an:
          </p>
          <address>
            Firma KiPA Nachrichtentechnik <br />
            Bundesstraße 18 <br />
            A-6063 Neu-Rum <br />
            <a href="tel:+43512266154">+43 512 26 61 54</a> <br />
            <a href="mailto:kipa@chello.at">kipa@chello.at</a>
          </address>
        </div>
      </Container>
    </div>
  )
}

export default CareerPage
